import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  Timestamp,
  updateDoc,
  orderBy,
  where,
} from 'firebase/firestore';
import { isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CommentBox from '../../components/commentbox/CommentBox';
import Like from '../../components/like/Like';
import FeatureBlogs from '../../components/FeatureBlogs';
import RelatedBlog from '../../components/relatedblog/RelatedBlog';
import Tags from '../../components/tags/Tags';
import UserComments from '../../components/UserComments';
import { db } from '../../firebase';
import Spinner from '../../components/spinner/Spinner';
import './detail.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BlogCard from '../../components/blogcard/BlogCard';
import Image from 'react-bootstrap/Image';
import { getFriendlyDateFormat } from '../../utility/index';

const Detail = ({ setActive, user }) => {
  const userId = user?.uid;
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [blog, setBlog] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [tags, setTags] = useState([]);
  const [comments, setComments] = useState([]);
  let [likes, setLikes] = useState([]);
  const [userComment, setUserComment] = useState('');
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [likeClicked, setLikeClicked] = useState(false);

  useEffect(() => {
    const getRecentBlogs = async () => {
      const blogRef = collection(db, 'blogs');
      const recentBlogs = query(
        blogRef,
        orderBy('timestamp', 'desc'),
        limit(5)
      );
      const docSnapshot = await getDocs(recentBlogs);
      setBlogs(docSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };

    getRecentBlogs();
  }, []);

  useEffect(() => {
    id && getBlogDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) {
    return <Spinner />;
  }

  const getBlogDetail = async () => {
    setLoading(true);
    const blogRef = collection(db, 'blogs');
    const docRef = doc(db, 'blogs', id);
    const blogDetail = await getDoc(docRef);
    const blogs = await getDocs(blogRef);
    let tags = [];
    blogs.docs.map((doc) => tags.push(...doc.get('tags')));
    let uniqueTags = [...new Set(tags)];
    setTags(uniqueTags);
    setBlog(blogDetail.data());
    const relatedBlogsQuery = query(
      blogRef,
      where('tags', 'array-contains-any', blogDetail.data().tags, limit(3))
    );
    setComments(blogDetail.data().comments ? blogDetail.data().comments : []);
    setLikes(blogDetail.data().likes ? blogDetail.data().likes : []);
    const relatedBlogSnapshot = await getDocs(relatedBlogsQuery);
    const relatedBlogs = [];
    relatedBlogSnapshot.forEach((doc) => {
      relatedBlogs.push({ id: doc.id, ...doc.data() });
    });
    setRelatedBlogs(relatedBlogs);
    setActive(null);
    setLoading(false);
  };

  const handleComment = async (e) => {
    e.preventDefault();
    comments.push({
      createdAt: Timestamp.fromDate(new Date()),
      userId,
      name: user?.displayName,
      body: userComment,
    });
    toast.success('Comment posted successfully');
    await updateDoc(doc(db, 'blogs', id), {
      ...blog,
      comments,
      timestamp: serverTimestamp(),
    });
    setComments(comments);
    setUserComment('');
  };

  const handleLike = async () => {
    if (userId) {
      if (blog?.likes) {
        const index = likes.findIndex((id) => id === userId);
        if (index === -1) {
          likes.push(userId);
          setLikes([...new Set(likes)]);
        } else {
          likes = likes.filter((id) => id !== userId);
          setLikes(likes);
        }
        setLikeClicked(!likeClicked);
      }
      await updateDoc(doc(db, 'blogs', id), {
        ...blog,
        likes,
        timestamp: serverTimestamp(),
      });
    }
  };

  console.log('relatedBlogs', relatedBlogs);
  console.log(`blog details `, blog);
  const imageStyle = {
    width: '800px',
    height: '400px',
    objectFit: 'cover',
  };

  const imageAuthor = {
    width: '50px',
    height: '50px',
  };
  return (
    <Container className="my-container">
      <Row>
        <Col sm={8}>
          <div className="containerDetail">
            <div className="containerDetailImgContent">
              <div className="my-image-container">
                <Image src={blog?.imgUrl} rounded fluid style={imageStyle} />
              </div>{' '}
            </div>
          </div>
          <div className="containerDetailBlogTitle"> {blog?.title} </div>
          <div className="containerDetailAuthorInfoSection">
            {/* <div className="containerDetailAuthorInfoImage">
              <Image
                src={blog?.imgUrl}
                roundedCircle
                fluid
                style={imageAuthor}
              />
            </div> */}
            <div className="containerDetailAuthorNameAndDate">
              <div className="containerDetailAuthorName"> {blog?.author} </div>{' '}
              <div className="containerDetailPostDate">
                {getFriendlyDateFormat(blog?.timestamp.toDate().toString())}
              </div>
            </div>
          </div>
          <div className="containerDetailBlogContent">
            content main goes here
          </div>
          <div className="containerDetail">
            {' '}
            <div className="containerDetailLikeSection">
              <Like handleLike={handleLike} likes={likes} userId={userId} />
            </div>
          </div>
        </Col>
        <Col sm={4}>
          <RelatedBlog id={id} blogs={relatedBlogs} />
        </Col>
      </Row>
    </Container>
  );
};

export default Detail;
