import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';
import { getFriendlyDateFormat } from '../../utility/index';
import { convertDateToMonthDayYYYY } from '../../utility/index';
import FontAwesome from 'react-fontawesome';
import './blogcard.css';

import React from 'react';

const BlogDetails = ({
  id,
  title,
  description,
  category,
  userId,
  author,
  timestamp,
  user,
  handleDelete,
}) => {
  return (
    <Card className="border-0">
      <Card.Body className="p-0 my-card">
        <Badge bg="info">{category}</Badge>
        <Card.Title>{title}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted author">
          {author} . {getFriendlyDateFormat(timestamp.toDate().toString())}
        </Card.Subtitle>
        <Card.Text>{description}</Card.Text>
        <Link to={`/detail/${id}`}>
          <Card.Link href="#" className="my-card-link">
            Read More ...
          </Card.Link>
        </Link>
      </Card.Body>
      {/* {console.log(user.uid)}
      {console.log(userId)} */}
      {user && user.uid === userId && (
        <div style={{ float: 'right' }}>
          <FontAwesome
            name="trash"
            style={{ margin: '15px', cursor: 'pointer', color: 'red' }}
            size="1.5x"
            onClick={() => handleDelete(id)}
          />
          <Link to={`/update/${id}`}>
            <FontAwesome
              name="edit"
              style={{ cursor: 'pointer', color: 'orange' }}
              size="1.5x"
            />
          </Link>
        </div>
      )}
    </Card>
  );
};

export default BlogDetails;
