export const excerpt = (str, count) => {
  if (str.length > count) {
    str = str.substring(0, count) + ' ... ';
  }
  return str;
};

// get date as string and presented in friendly format
// if the date is not this year display as : mm/dd/yyyy 12/21/2023
// if the date is this year and not today display as : Month day eg. Feb 23
// if the date is today than display as HH:MM am or pm eg. 12:30 pm
export function getFriendlyDateFormat(datestr) {
  const now = new Date();
  const dateObj = new Date(datestr);
  const localDate = new Date(
    dateObj.getTime() - dateObj.getTimezoneOffset() * 60 * 1000
  );
  const localDateMonthName = localDate.toLocaleDateString('en', {
    month: 'short',
  });
  const localMonthNumber = localDate.getMonth();
  const localDateDay = localDate.getDate();
  const hour = localDate.getHours();
  const hour12 = hour % 12 || 12;
  // get the minute ( 0 - 59)
  const minute = localDate.getMinutes();
  const minutestr = minute.toString().length < 2 ? `0${minute}` : minute;
  // get the AM/PM indicator
  const ampm = hour < 12 ? 'am' : 'pm';
  if (localDate.getFullYear() !== now.getFullYear()) {
    return `${localMonthNumber}/${localDateDay}/${localDate.getFullYear()}`;
  } else if (
    localDate.getFullYear() === now.getFullYear() &&
    localDateDay !== now.getDate()
  ) {
    return `${localDateMonthName} ${localDateDay}`;
  } else {
    return `${hour12}:${minutestr} ${ampm}`;
  }
}

// get date as string and presented in friendly format
// if the date is not this year display as : mm/dd/yyyy 12/21/2023
// if the date is this year and not today display as : Month day eg. Feb 23
// if the date is today than display as HH:MM am or pm eg. 12:30 pm
export function convertDateToMonthDayYYYY(datestr) {
  const now = new Date();
  const dateObj = new Date(datestr);
  const localDate = new Date(
    dateObj.getTime() - dateObj.getTimezoneOffset() * 60 * 1000
  );
  const localDateMonthName = localDate.toLocaleDateString('en', {
    month: 'short',
  });
  const localMonthNumber = localDate.getMonth();
  const localDateDay = localDate.getDate();
  const localFullYear = localDate.getFullYear();
  return `${localDateMonthName} ${localDateDay} , ${localFullYear}`;
}
