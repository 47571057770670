import React from 'react';
import { Link } from 'react-router-dom';
import './category.css';
import ListGroup from 'react-bootstrap/ListGroup';

const Category = ({ catgBlogsCount }) => {
  return (
    <div className="containerCategory">
      <div className="categoryTitle">Category</div>

      <div className="contentCategory">
        <ul>
          {catgBlogsCount?.map((item, index) => (
            <li key={index}>
              <Link
                to={`/category/${item.category}`}
                style={{ textDecoration: 'none', float: 'left', color: '#777' }}
              >
                {item.category}
                <span>({item.count})</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Category;
