import React from 'react';
import Card from '../../components/card/Card';
import './relatedblog.css';

const RelatedBlog = ({ blogs, id }) => {
  return (
    <div className="containterRelatedBlog">
      <div className="relatedBlogTitle">Related Blogs v</div>
      <div className="relatedBlogCardContent">
        {blogs.length === 0 && (
          <h5 className="text-center">
            Related Blogs not found with this current blog
          </h5>
        )}
        {blogs
          ?.filter((blogs) => blogs.id !== id)
          .map((item) => (
            <Card {...item} />
          ))}
      </div>
    </div>
  );
};

export default RelatedBlog;
