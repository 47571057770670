import { useState, useEffect } from 'react';
import './App.css';

import Home from './pages/home/Home';
import About from './pages/about/About';
import Search from './components/search/Search';
import Blogs from './pages/blogs/Blogs';
import TagBlog from './pages/tagblog/TagBlog';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Detail from './pages/detail/Detail';
import AddBlog from './pages/addblog/AddBlog';
import TopBar from './components/topbar/TopBar';
import TopBar1 from './components/topbar1/TopBar1';
import NavBar from './components/navbar/NavBar';
// import { Routes, Route, Router, useNavigate } from 'react-router-dom';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddEditBlog from './pages/editblog/AddEditBlog';
import NotFound from './pages/NotFound';
// import Header from './components/Header';
import Auth from './pages/auth/Auth';
import { auth } from './firebase';
import { signOut } from 'firebase/auth';
// import TagBlog from './pages/TagBlog';
import CategoryBlog from './pages/categoryblog/CategoryBlog';
// import ScrollToTop from './components/ScrollToTop';
// import Blogs from './pages/Blogs';

function App() {
  const [active, setActive] = useState('home');
  const [user, setUser] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });
  }, []);

  const handleLogout = () => {
    signOut(auth).then(() => {
      setUser(null);
      setActive('login');
      navigate('/auth');
    });
  };

  return (
    <div className="App">
      {/* <TopBar1
        setActive={setActive}
        active={active}
        user={user}
        handleLogout={handleLogout}
      /> */}
      <TopBar1 user={user} handleLogout={handleLogout}></TopBar1>
      <Routes>
        <Route
          path="/"
          element={<Home setActive={setActive} active={active} user={user} />}
        />
        <Route
          path="/search"
          element={<Home setActive={setActive} user={user} />}
        />
        <Route
          path="/detail/:id"
          element={<Detail setActive={setActive} user={user} />}
        />

        <Route
          path="/create"
          element={
            user?.uid ? <AddEditBlog user={user} /> : <Navigate to="/auth" />
          }
        />
        <Route path="/about" element={<About />} />
        <Route
          path="/auth"
          element={<Auth setActive={setActive} setUser={setUser} />}
        />
        <Route
          path="/update/:id"
          element={
            user?.uid ? (
              <AddEditBlog user={user} setActive={setActive} />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route path="/blogs" element={<Blogs setActive={setActive} />} />
        <Route path="/tag/:tag" element={<TagBlog setActive={setActive} />} />
        <Route
          path="/category/:category"
          element={<CategoryBlog setActive={setActive} />}
        />
      </Routes>
    </div>
  );
}

export default App;
