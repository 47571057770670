import './about.css';
import React, { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import MyImage from '../../../src/images/myprofilepic1.jpg';

const About = ({ setActive, user }) => {
  return (
    <Container className="my-container">
      <Row>
        <Col sm={8}>
          <div className="containerAbout">main content goes here </div>
        </Col>
        <Col sm={4}>
          <div className="containerAboutImage">
            <div className="circle-image-container">
              <img src={MyImage} alt={'My profile'} className="circle-image" />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
