import React, { useEffect } from 'react';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { excerpt } from '../../utility';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

// import Logo from '../images/Logo6.1.png';
import Logo from '../../images/fruits1.jpeg';
import melon from '../../images/wmelon.jepg.jpeg';
import BlogDetails from './BlogDetails';

const BlogSection = ({
  id,
  title,
  description,
  category,
  imgUrl,
  userId,
  author,
  timestamp,
  user,
  handleDelete,
}) => {
  const imageStyle = {
    width: '350px',
    height: '200px',
    objectFit: 'cover',
  };
  return (
    <Container fluid className="no-left-padding">
      <Row className="my-row">
        <Col sm={4}>
          {' '}
          <div className="my-image-container">
            <Image src={imgUrl} rounded fluid style={imageStyle} />
          </div>
        </Col>
        <Col sm={8}>
          {' '}
          <BlogDetails
            id={id}
            title={title}
            description={description}
            timestamp={timestamp}
            author={author}
            category={category}
            user={user}
            userId={userId}
            handleDelete={handleDelete}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default BlogSection;
