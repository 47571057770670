import React from 'react';
import Badge from 'react-bootstrap/Badge';

import { Link } from 'react-router-dom';

import './tags.css';

export default function MyTags({ tags }) {
  const tags1 = [
    'tag1',
    'tag2',
    'tag3',
    'tag4 market',
    'hello',
    'tag5 technology',
  ];
  return (
    <div className="containerTags">
      <div className="tagTitle">Tags</div>

      <div className="contentTags">
        {tags?.map((tag, index) => (
          <Badge bg="warning tag1">
            <Link
              to={`/tag/${tag}`}
              style={{ textDecoration: 'none', color: 'black' }}
            >
              {tag}
            </Link>
          </Badge>
        ))}
      </div>
    </div>
  );
}
