import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  onSnapshot,
  query,
  orderBy,
  where,
  startAfter,
} from 'firebase/firestore';
import React, { useState, useEffect } from 'react';
import BlogSection from '../../components/blogsection/BlogSection';
import Spinner from '../../components/Spinner';
import { db } from '../../firebase';
import { toast } from 'react-toastify';
import Tags from '../../components/tags/Tags';
import FeatureBlogs from '../../components/FeatureBlogs';
import Trending from '../../components/Trending';
import Search from '../../components/search/Search';
import { isEmpty, isNull } from 'lodash';
import { useLocation } from 'react-router-dom';
import Category from '../../components/category/Category';
import './home.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BlogCard from '../../components/blogcard/BlogCard';

import MySearch from '../../components/search/MySearch';
import MyTags from '../../components/tags/MyTags';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Home = ({ setActive, user, active }) => {
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [tags, setTags] = useState([]);
  const [search, setSearch] = useState('');
  const [lastVisible, setLastVisible] = useState(null);
  const [trendBlogs, setTrendBlogs] = useState([]);
  const [totalBlogs, setTotalBlogs] = useState(null);
  const [hide, setHide] = useState(false);
  const queryString = useQuery();
  const searchQuery = queryString.get('searchQuery');
  const location = useLocation();

  const getTrendingBlogs = async () => {
    const blogRef = collection(db, 'blogs');
    const trendQuery = query(blogRef, where('trending', '==', 'yes'));
    const querySnapshot = await getDocs(trendQuery);
    let trendBlogs = [];
    querySnapshot.forEach((doc) => {
      trendBlogs.push({ id: doc.id, ...doc.data() });
    });
    setTrendBlogs(trendBlogs);
  };

  useEffect(() => {
    getTrendingBlogs();
    setSearch('');
    const unsub = onSnapshot(
      collection(db, 'blogs'),
      (snapshot) => {
        let list = [];
        let tags = [];
        snapshot.docs.forEach((doc) => {
          tags.push(...doc.get('tags'));
          list.push({ id: doc.id, ...doc.data() });
        });
        const uniqueTags = [...new Set(tags)];
        setTags(uniqueTags);
        setTotalBlogs(list);
        // setBlogs(list);
        setLoading(false);
        setActive('home');
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
      getTrendingBlogs();
    };
  }, [setActive, active]);

  useEffect(() => {
    getBlogs();
    setHide(false);
  }, [active]);

  const getBlogs = async () => {
    const blogRef = collection(db, 'blogs');
    console.log(blogRef);
    const firstFour = query(blogRef, orderBy('title'), limit(4));
    const docSnapshot = await getDocs(firstFour);
    setBlogs(docSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    setLastVisible(docSnapshot.docs[docSnapshot.docs.length - 1]);
  };

  console.log('blogs', blogs);

  const updateState = (docSnapshot) => {
    const isCollectionEmpty = docSnapshot.size === 0;
    if (!isCollectionEmpty) {
      const blogsData = docSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBlogs((blogs) => [...blogs, ...blogsData]);
      setLastVisible(docSnapshot.docs[docSnapshot.docs.length - 1]);
    } else {
      toast.info('No more blog to display');
      setHide(true);
    }
  };

  const fetchMore = async () => {
    setLoading(true);
    const blogRef = collection(db, 'blogs');
    const nextFour = query(
      blogRef,
      orderBy('title'),
      limit(4),
      startAfter(lastVisible)
    );
    const docSnapshot = await getDocs(nextFour);
    updateState(docSnapshot);
    setLoading(false);
  };

  const searchBlogs = async () => {
    const blogRef = collection(db, 'blogs');
    const searchTitleQuery = query(blogRef, where('title', '==', searchQuery));
    const searchTagQuery = query(
      blogRef,
      where('tags', 'array-contains', searchQuery)
    );
    const titleSnapshot = await getDocs(searchTitleQuery);
    const tagSnapshot = await getDocs(searchTagQuery);

    let searchTitleBlogs = [];
    let searchTagBlogs = [];
    titleSnapshot.forEach((doc) => {
      searchTitleBlogs.push({ id: doc.id, ...doc.data() });
    });
    tagSnapshot.forEach((doc) => {
      searchTagBlogs.push({ id: doc.id, ...doc.data() });
    });
    const combinedSearchBlogs = searchTitleBlogs.concat(searchTagBlogs);
    setBlogs(combinedSearchBlogs);
    setHide(true);
    setActive('');
  };

  useEffect(() => {
    console.log(`inside use effect for search`);
    if (!isNull(searchQuery)) {
      console.log('calling search Blogs');
      searchBlogs();
    }
  }, [searchQuery]);

  if (loading) {
    return <Spinner />;
  }

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure wanted to delete that blog ?')) {
      try {
        setLoading(true);
        await deleteDoc(doc(db, 'blogs', id));
        toast.success('Blog deleted successfully');
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    console.log(`value ${value}`);
    if (isEmpty(value)) {
      console.log('test');
      getBlogs();
      setHide(false);
    }
    setSearch(value);
  };

  // category count
  const counts = totalBlogs.reduce((prevValue, currentValue) => {
    let name = currentValue.category;
    if (!prevValue.hasOwnProperty(name)) {
      prevValue[name] = 0;
    }
    prevValue[name]++;
    // delete prevValue["undefined"];
    return prevValue;
  }, {});

  const categoryCount = Object.keys(counts).map((k) => {
    return {
      category: k,
      count: counts[k],
    };
  });

  console.log('categoryCount', categoryCount);

  return (
    <Container className="my-container">
      <Row className="flex-wrap">
        <Col sm={8}>
          {blogs?.map((blog) => (
            <BlogCard
              key={blog.id}
              user={user}
              handleDelete={handleDelete}
              {...blog}
            />
          ))}
          {!hide && (
            <button className="btn btn-primary" onClick={fetchMore}>
              Load More Blogs ..
            </button>
          )}
        </Col>

        <Col sm={4}>
          <MySearch search={search} handleChange={handleChange} />
          <MyTags tags={tags} />
          <Category catgBlogsCount={categoryCount} />
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
