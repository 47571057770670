import React from 'react';
import { Link } from 'react-router-dom';
import { excerpt } from '../../utility/index';
import './card.css';

const Card = ({ title, description, imgUrl, id, likes, comments }) => {
  return (
    <div className="containerCard">
      <div className="containerCardContent">
        <img className="cardImg" src={imgUrl} alt={title} />
        <h5 className="cardTitle">{title}</h5>
        <p className="cardDesc">{excerpt(description, 25)}</p>
        <div className="cardReadMoreSection">
          <Link to={`/detail/${id}`} style={{ textDecoration: 'none' }}>
            <span className="cardReadMore">Read More</span>
          </Link>
          <div className="cardLikeAndCommentsSection">
            <i className="bi bi-hand-thumbs-up m-2" />
            {likes.length}
            <i className="bi bi-chat-left m-2" />
            {comments.length}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
