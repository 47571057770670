import Container from 'react-bootstrap/Container';
import { NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import Logo from '../../images/LogoMyName1.png';
import './topbar1.css';

const TopBar1 = ({ active, setActive, user, handleLogout }) => {
  const userId = user?.uid;
  const menuData = [
    {
      path: '/',
      name: 'HOME',
    },
    {
      path: '/blogs',
      name: 'BLOGS',
    },
    {
      path: '/about',
      name: 'ABOUT',
    },
    {
      path: '/create',
      name: 'CREATE',
    },
  ];
  return (
    <Navbar className="navbar" expand="lg">
      <Container>
        <Navbar.Brand href="/" className="brand">
          {/* <img src={Logo} alt="My Logo" /> */}
          <div className="logo-container">
            <div className="logo-firstname1">Youvaraj</div>
            <div className="logo-lastname">ACHARYA</div>
            <i class="fa-solid fa-seedling logo-icon"></i>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {menuData.map((item) => (
              <NavLink to={item.path} key={item.name}>
                <div className="list_item">{item.name}</div>
              </NavLink>
            ))}
          </Nav>

          <Nav className="ms-auto">
            <div className="userinfo-signupbutton">
              {userId ? (
                <>
                  <div className="user-info">
                    <div className="profile-logo">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                        alt="logo"
                        style={{
                          width: '30px',
                          height: '30px',
                          borderRadius: '50%',
                          marginTop: '12px',
                        }}
                      />
                    </div>
                    <p style={{ marginTop: '12px', marginLeft: '5px' }}>
                      {user?.displayName}
                    </p>
                  </div>
                  <div className="btn-container">
                    <button className="btn btn-success" onClick={handleLogout}>
                      Logout
                    </button>
                  </div>
                </>
              ) : (
                <Link to="/auth" style={{ textDecoration: 'none' }}>
                  <button className="btn btn-success">Sign Up</button>
                </Link>
              )}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TopBar1;
