import React from 'react';
import { Link } from 'react-router-dom';
import './tags.css';

const Tags = ({ tags }) => {
  // const tags1 = [
  //   'tag1',
  //   'tag2',
  //   'tag3',
  //   'tag4 market',
  //   'hello',
  //   'tag5 technology',
  // ];

  return (
    <div className="containerTags">
      <div className="tagTitle">Tags</div>
      <div className="contentTags">
        {tags?.map((tag, index) => (
          <p className="tag1" key={index}>
            <Link
              to={`/tag/${tag}`}
              style={{ textDecoration: 'none', color: 'black' }}
            >
              {tag}
            </Link>
          </p>
        ))}
      </div>
    </div>
  );
};

export default Tags;
