// Your web app's Firebase configuration
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBXEUh4Tjkbs-pDYFiTNGewnRWksdGj07s',
  authDomain: 'youvaraj-blog.firebaseapp.com',
  projectId: 'youvaraj-blog',
  storageBucket: 'youvaraj-blog.appspot.com',
  messagingSenderId: '288652573575',
  appId: '1:288652573575:web:6e5ec6247f1df93c683122',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
