import React from 'react';
import { useNavigate } from 'react-router-dom';
import './search.css';
import { FaSearch } from 'react-icons/fa';

const Search = ({ search, handleChange }) => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (search) {
      navigate(`/search?searchQuery=${search}`);
    } else {
      navigate('/');
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const searchText = event.target.value;
    console.log(`search clicked and value is ${searchText}`);
    if (search) {
      navigate(`/search?searchQuery=${search}`);
    } else {
      navigate('/');
    }
    // Perform search logic here
  };

  return (
    <div className="containerSearch">
      <div className="searchTitle">Search blogs </div>
      <div className="search-field">
        <input type="text" placeholder="Search..." onChange={handleChange} />
        <FaSearch className="search-icon" onClick={handleSearch} />
      </div>
    </div>
  );
};

export default Search;
